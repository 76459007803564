<template>
  <button
    class="focus:outline-none rounded-md bg-indigo-500 px-4 py-1.5 text-sm font-semibold hover:bg-indigo-600 focus:ring disabled:cursor-default disabled:opacity-50"
    :class="{
      'bg-rose-500 hover:bg-rose-600 focus:ring-rose-300': variant == 'danger',
      'text-slate-700': textVariant == 'dark',
      'text-white': textVariant == 'light',
    }"
    :style="{
      backgroundColor: variant[500]
        ? hover
          ? variant[600]
          : variant[500]
        : null,
      '--tw-ring-color': variant[300],
    }"
    :disabled="disabled"
    :type="type"
    @click="command"
    @focus="focus = true"
    @focusout="focus = false"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="flex items-center justify-center gap-2">
      <component v-if="icon" class="h-4 w-4" :is="icon" />
      <span v-if="label">{{ t(`buttons.${label}`) }}</span>
    </div>
  </button>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
export default {
  props: {
    command: {
      default: () => null,
      required: false,
      type: Function,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    icon: {
      required: false,
    },
    label: {
      type: String,
    },
    textVariant: {
      default: "light",
      type: String,
    },
    type: {
      default: "button",
      type: String,
    },
    variant: {
      default: "indigo",
      type: [Object, String],
    },
  },
  setup() {
    const focus = ref(false);
    const hover = ref(false);
    const { t } = useI18n();
    return {
      focus,
      hover,
      t,
    };
  },
};
</script>
